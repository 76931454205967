<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="日报日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="姓名|电话"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="reportDate" slot-scope="scope">
          <el-button type="text" @click="$site.view_virusdailyreport_detail(scope.row.reportId)">{{ scope.row.reportDate }}</el-button>
        </template>
        <template slot="leaveTime" slot-scope="scope">
          <p>
            {{ scope.row.leaveTime | limitTo(10) }}
          </p>
          <p class="text-cut">
            {{ scope.row.leaveReason }}
          </p>
        </template>
        <template slot="backTime" slot-scope="scope">
          <p>
            {{ scope.row.backTime | limitTo(10) }}
          </p>
          <p class="text-cut">
            {{ scope.row.backFrom }}
          </p>
        </template>
        <template slot="frksFlag" slot-scope="scope">
          <span v-if="scope.row.frksFlag == 0" class="text-blue">无</span>
          <span v-else-if="scope.row.frksFlag == 1" class="text-green">有</span>
        </template>
        <template slot="workFlag" slot-scope="scope">
          <span v-if="scope.row.workFlag == 0" class="text-blue">未上班</span>
          <span v-else-if="scope.row.workFlag == 1" class="text-green">上班</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MyCarBorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '日期', slot: 'reportDate' },
          { label: '姓名', prop: 'userName' },
          { label: '电话', prop: 'userMobile' },
          { label: '部门', prop: 'departName' },
          { label: '是否发热咳嗽', slot: 'frksFlag' },
          { label: '是否上班', slot: 'workFlag' },
          { label: '体温', prop: 'temp' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-printer', label: '详细信息', click: this.detail }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/virusdailyreport/list', ['enterpriseCode', this.condition])
    },
    detail(item) {
      this.$site.view_virusdailyreport_detail(item.reportId)
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/virusdailyreport/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url, download: '防疫日报导出.xlsx' } }, '点击下载'))
      })
    }
  }
}
</script>
